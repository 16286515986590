import { displayDecimal } from "utils/helpers";

export class Discount {
    constructor(
        readonly text: string,
        readonly value: number) {
    }

    calculareForPrice(price: number | undefined): number {
        if(!price) {
            return 0;
        }
        return price * (1 - (this.value) / 100);
    }

    forPrice(price: number | undefined): string {
        if (!price) {
            return ''
        };
        return displayDecimal(price * (1 - (this.value / 100)));
    }
}
