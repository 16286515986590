/* eslint-disable @typescript-eslint/no-explicit-any */
import Link from 'next/link';
import Router from 'next/router';
import { Badge, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';

import ShoppingCart from '../../classes/shoppingCart';
import { getCartStore } from '../../store/cart';
import { api } from '../../utils/api';
import ProductsMenuElements from '../ProductsMenuElements';
import DiscountComponent from '../DiscountComponent';
import { useGlobalDiscount } from 'dal/useGlobalDiscount';
import useCurrentUser from 'dal/useCurrentUser';
import useProducts from 'dal/useProducts';
import Image from 'next/image';
import ShoppingCartProducts from 'models/shoppingCartProducts';

type Props = {
    displayHomeLink?: boolean;
    profile?: boolean;
};

export default function CustomHeader(props: Props): any {
    const { discount } = useGlobalDiscount();
    const { user, mutateUser } = useCurrentUser();

    const [cartSelectedItems, setCartSelectedItems] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const { products } = useProducts();

    const logoutUser = () => {
        api
            .post('/auth/logout')
            .then(() => {
                mutateUser();
                Router.push('/login');
            });
    };

    const handleChangeWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        window.onresize = handleChangeWindowSize;
    }, [windowWidth]);

    useEffect(() => {
        const eventListener = (e: Event) => {
            const event = e as CustomEvent<ShoppingCartProducts>;
            const productsCount = Object.values(event.detail).reduce((a, b) => a + b, 0);
            setCartSelectedItems(productsCount);
        };

        getCartStore().addEventListener('shoppingCartUpdated', eventListener, false);

        return () => {
            getCartStore().removeEventListener('shoppingCartUpdated', eventListener);
        }
    }, []);

    const menu = (
        <Menu style={{ width: 150, zIndex: 60000000000 }}>
            {user?.id ? (
                <>
                    {!user?.isAdmin && (
                        <Menu.Item key="profile">
                            <Link href="/profile">Profile</Link>
                        </Menu.Item>
                    )}
                    {user?.isAdmin && (
                        <Menu.Item key="admin-panel">
                            <Link href="/admin/dashboard">Admin panel</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="logout">
                        <a onClick={logoutUser}>Logout</a>
                    </Menu.Item>
                </>
            ) : (
                <>
                    <Menu.Item key="login">
                        <Link href="/login">Log in</Link>
                    </Menu.Item>
                    <Menu.Item key="sign-up">
                        <Link href="/register">Sign up</Link>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );

    const dropdownIsDisable = windowWidth <= 1023;

    const productsDropdownMenu = !dropdownIsDisable ? <ProductsMenuElements products={products} {...props} windowWidth={windowWidth} /> : <></>;

    const showDiscountBar = discount != null && !props.profile;

    return (
        <>

            <div id='discountBar'>
                {showDiscountBar && <DiscountComponent />}
            </div>
            <header className="header header_no-offset header-font" id="header" style={{ zIndex: 100, padding: '20px' }}>
                <div className="header__fixed" style={{ marginTop: showDiscountBar ? 40 : 0, backgroundColor: '#0a141f' }}>
                    <div className="container header__container">
                        <Link href="/" passHref>
                            <a className="logo header__logo" style={{ padding: '20px' }}>
                                <img className="logo__img" src="/resources/images/logo-main.svg" alt="Glyde Logotype" width="166" height="54" />
                            </a>
                        </Link>
                        <nav className="header__nav" id="menu" style={{ marginTop: discount != null && windowWidth < 1024 ? 50 : 0 }}>
                            <ul className="navmain">
                                {props.displayHomeLink && (
                                    <li className="navmain__item">
                                        <Link href="/" passHref>
                                            <a className="navmain__link">Home</a>
                                        </Link>
                                    </li>
                                )}
                                <li className="navmain__item">
                                    <Dropdown overlay={productsDropdownMenu} placement={'bottomCenter'}>
                                        {dropdownIsDisable
                                            ? (
                                                <Link href='/products'>
                                                    <span className="navmain__link navmain__link_drop">Products</span>
                                                </Link>
                                            )
                                            : <span className="navmain__link navmain__link_drop">Products</span>
                                        }
                                    </Dropdown>
                                </li>
                                <li className="navmain__item">
                                    <Link href="/quiz" passHref>
                                        <a className="navmain__link">Shop</a>
                                    </Link>
                                </li>
                                <li className="navmain__item">
                                    <Link href="/about-us" passHref>
                                        <a className="navmain__link">About Us</a>
                                    </Link>
                                </li>
                                <li className="navmain__item">
                                    <Link href="/contact-us" passHref>
                                        <a className="navmain__link">Contact Us</a>
                                    </Link>
                                </li>
                                <li className="navmain__item" style={{ display: 'flex' }}>
                                    <a className="header__action-link header__action-callback" href="tel:888-994-5933" aria-label="Callback">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.059 24.059" fill="#242426" style={{ fill: '#fff' }}>
                                            <g data-name="Group 1740">
                                                <path
                                                    data-name="Path 3275"
                                                    d="M24.057 19.386a3.559 3.559 0 00-1.092-2.445 8.741 8.741 0 00-3.461-2.3 3.873 3.873 0 00-3.977 1.129l-.005.005-1.271 1.261a19.042 19.042 0 01-4.006-3.1l-.124-.124a19.043 19.043 0 01-3.1-4.006l1.263-1.269.005-.005a3.873 3.873 0 001.129-3.978 8.742 8.742 0 00-2.299-3.456A3.576 3.576 0 002.155.914l-.027.025-.036.034A7.625 7.625 0 000 6.559c.019 3.864 2.143 8.282 5.68 11.819a22.906 22.906 0 002.253 1.962.94.94 0 001.144-1.492 21.044 21.044 0 01-2.068-1.8c-3.2-3.2-5.113-7.12-5.13-10.5A5.806 5.806 0 013.4 2.32l.007-.006a1.691 1.691 0 012.359.084C8.2 4.921 8.023 6.113 6.947 7.215L5.205 8.97a.94.94 0 00-.192 1.044 18.99 18.99 0 003.779 5.128l.124.124a18.987 18.987 0 005.128 3.778.94.94 0 001.044-.191l1.755-1.742c1.1-1.075 2.294-1.251 4.817 1.182a1.691 1.691 0 01.085 2.354l-.007.007a5.789 5.789 0 01-4.187 1.524h-.043a12.187 12.187 0 01-4.637-1.1.94.94 0 00-.744 1.726 13.865 13.865 0 005.373 1.255h.053a7.609 7.609 0 005.533-2.092l.035-.037.024-.027a3.559 3.559 0 00.912-2.517z"
                                                ></path>
                                            </g>
                                        </svg>
                                    </a>
                                    <a className="header__action-link header__action-callback header__action-number" href="tel:888-994-5933" aria-label="Callback">
                                        888.994.5933
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="header__action">
                            <Link href="/search" passHref>
                                <a className="header__action-link header__action-search" aria-label="Search">
                                    <svg
                                        data-name="Group 874"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21.719 21.719"
                                        style={{ fill: '#fff' }}
                                    >
                                        <g data-name="Group 872">
                                            <path
                                                data-name="Path 701"
                                                d="M10.141 20.281a10.141 10.141 0 117.174-2.967 10.115 10.115 0 01-7.174 2.967zm0-18.311a8.17 8.17 0 105.78 2.39 8.153 8.153 0 00-5.78-2.39z"
                                            ></path>
                                        </g>
                                        <g data-name="Group 873">
                                            <path data-name="Rectangle 423" d="M15.921 17.314l1.394-1.394 4.404 4.405-1.393 1.394z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </Link>
                            <Link href="/shopping-cart" passHref>
                                <a className="header__action-link header__action-buy" aria-label="Buy">
                                    <Badge count={cartSelectedItems}>
                                        <Image src="/resources/images/cart.svg" width="20" height="26" />
                                    </Badge>
                                </a>
                            </Link>
                            <Dropdown overlay={menu}>
                                <a className="header__action-link header__action-account" aria-label="Account" style={{ zIndex: 100000000 }} href="/profile">
                                    <svg
                                        data-name="Group 880"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="25"
                                        viewBox="0 0 19.075 25.39"
                                        style={{ fill: '#fff' }}
                                    >
                                        <g data-name="Group 878">
                                            <path
                                                data-name="Path 704"
                                                d="M9.538 13.121c-3.435 0-6-3.654-6-6.922a6 6 0 1112 0c.002 3.268-2.565 6.922-6 6.922zm0-11.15a4.137 4.137 0 00-4.031 4.228c0 2.29 1.761 4.95 4.031 4.95s4.03-2.66 4.03-4.95a4.137 4.137 0 00-4.03-4.228z"
                                            ></path>
                                        </g>
                                        <g data-name="Group 879">
                                            <path
                                                data-name="Path 705"
                                                d="M9.869 25.39H9.52c-.672.012-6.9.061-9.164-1.919L0 23.159l.02-.474c0-.076.443-7.646 9.517-7.646s9.513 7.57 9.517 7.646l.02.474-.356.312c-2.044 1.786-7.33 1.919-8.849 1.919zm-.349-1.974c2.186.037 5.894-.254 7.5-1.154-.262-1.415-1.52-5.255-7.481-5.255-6.006 0-7.236 3.835-7.485 5.253 1.6.901 5.308 1.194 7.466 1.156z"
                                            ></path>
                                        </g>
                                    </svg>
                                </a>
                            </Dropdown>
                        </div>
                        <button className="header__menu-btn" type="button" aria-label="Open menu" id="menuBtn">
                            <span></span>
                        </button>
                    </div>
                </div>
            </header>
        </>
    );
}
