import { Layout } from 'antd';
import dynamic from 'next/dynamic';
const CustomFooter = dynamic(() => import('../components/templates/CustomFooter'));
import CustomHeader from '../components/templates/CustomHeader';
import CustomLayout from '../components/templates/CustomLayout';
import $ from 'jquery';
import {useEffect} from 'react';
const CustomerChat = dynamic(() => import('../components/CustomerChat'));


export default function GuestTemplate(props: any): JSX.Element {
    const activateMobileMenuIfNeeded = () => {
        const jQuery: typeof $ = (window as any).$ as typeof $;

        if(jQuery) {
            jQuery("#menuBtn").off('click').on('click', function(e) {
                (window as any).toggleMenu(e, jQuery(this), jQuery('#menu'));
                let hideMenu = (window as any).hideMenu;
                jQuery('#menuBtn').on('click', function () {
                    jQuery('#discountBar').toggleClass('hide-discount'), jQuery('#menu').toggleClass('hide-discount-active'), jQuery('.header__fixed').toggleClass('hide-discount-active'), jQuery('.navmain__item').on('click', hideMenu), jQuery('.header__action-link').on('click', hideMenu)
                });
                jQuery('.navmain__item').on('click', hideMenu);
                jQuery(window).on("resize orientationchange", function () { jQuery("#menu.is-open").length && hideMenu() })
            });
        }
    }

    useEffect(() => {
        activateMobileMenuIfNeeded();
    }, []);


    return (
        <CustomLayout style={{ backgroundColor: '#FAFAF9' }}>
            <CustomHeader />
            <Layout.Content>
                {props.children}
            </Layout.Content>
            <CustomFooter {...props} />
            <CustomerChat />
        </CustomLayout >
    );
}
