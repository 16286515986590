import useSWR from 'swr'
import { Discount } from 'models/discount';

type Result = { discount: Discount | null }

export function useGlobalDiscount() : Result {
    const { data : { discountValues = [] } = {} } = useSWR(`/public/discounts`);

    if (discountValues.length === 0 || !discountValues[0].isActive) {
      return {
        discount: null
      }
    }

    return {
      discount: new Discount(discountValues[0].text, discountValues[0].value)
    }
}
