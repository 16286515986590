import { Col, Row } from 'antd';
import GroupedProduct from '../models/groupedProduct';
import Product from '../models/product';
import { CustomLinkButton } from '../custom';
import Link from 'next/link';

type Props = {
    products: GroupedProduct[];
    windowWidth: number;
}

const ProductsMenuElements = (props: any): JSX.Element => {
    const { products, windowWidth } = props;

    const mediumSizeStyle = windowWidth > 1023 && windowWidth <= 1500;

    const productsMenuElements = products.map((gp: any) => (
        <Col
            key={gp.category}
            span={12}
            style={{ marginBottom: '5px' }}
        >
            <Row
                style={{ fontWeight: 'bold', color: '#ff8d6d' }}>
                {gp.category}
            </Row>
            {gp.products
                .map((p: Product) => (
                    <Row
                        key={p.id}
                        style={{ fontSize: mediumSizeStyle ? 10 : 14, padding: '5px 0' }} 
                   >
                        <Link
                            href={`/products/${p.id}`}
                            passHref
                        >
                            <a style={{ color: '#000' }}>{p.name}</a>
                        </Link>
                    </Row>
                ))
            }
        </Col>
    ));
    return (
        <div className="drop-down-products">
            <Row className="header__nav-products-menu" gutter={24}>
                {productsMenuElements}
            </Row>
            <Row className="drop-down-all-products" gutter={24}>
                <Link href="/products">
                    <CustomLinkButton style={{ margin: '20px 10px', fontWeight: 'bold' }} >View all products</CustomLinkButton>
                </Link>
            </Row>
        </div >
    );
};

export default ProductsMenuElements;
