import { useGlobalDiscount } from 'dal/useGlobalDiscount';
import styled from 'styled-components';
import constants from '../utils/constants';

interface Props {
    readonly discount: boolean;
  }

const StyledDiscount = styled.div<Props>`
width: 100vw; 
height: 40px; 
background-color: ${constants.COLOR.ACCENT_COLOR}; 
position: fixed; 
top: 0;
left: 0;
display: ${props => props.discount != null ? 'flex' : 'none'};
justify-content: center; 
align-items: center; 
font-weight: bold; 
color: #fff;
z-index: 999;
`;

const DiscountComponent = () => {
    const { discount } = useGlobalDiscount();

    return (
        <StyledDiscount discount={discount != null}>
            {discount?.text || ''}
        </StyledDiscount>
    );
};

export default DiscountComponent;
