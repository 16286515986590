import { LayoutProps } from 'antd/lib/layout';
import { Layout } from 'antd';
import styled from 'styled-components';

const StyledLayout = styled(Layout)`
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
`;

export default function CustomLayout(props: LayoutProps) {
    return (
        <StyledLayout {...props}>
            {props.children}
        </StyledLayout>
    );

}
